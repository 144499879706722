import React, { PureComponent } from 'react';

import { ArkResult } from '../Components';
class ChatBottom extends PureComponent {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this)

		window.recodeSend = this.sttSend.bind(this);
	}

	componentDidUpdate() {
		if (this.props.clearTextbox === true) {
			this.input.value = '';
		}
	}
	focusIndex = -1;
	handleChange(e) {
		if (this.props.useArk) {
			this.props.getArk({ value: e.target.value, isDev: this.props.config.isDev, projectId: this.props.config.projectId }); // 자동완성을 사용하지 않는 경우에는 리퀘스트를 날리지 않음
			this.focusIndex = -1;
		}
	}

	handleKeyPress(e) {
		//console.info(this.ark)
		if (e.charCode === 13) {
			this.send();
		} 
	}

	
	handleKeyDown(e) {
		//console.info(e.keyCode)
		//console.info(this.focusIndex)
		//console.info(this.ark.getElementsByClassName('qna_item'))
		if (e.keyCode === 38 || e.keyCode === 40) {
			let arkItems = document.getElementsByClassName('qna_link');
			//console.info(arkItems.length)
			if (arkItems.length === 0) {
				this.focusIndex = -1;
			} else {
				if (this.focusIndex === -1 && e.keyCode === 38) {
					this.focusIndex = 0;
				} else if (e.keyCode === 38) {
					this.focusIndex = this.focusIndex - 1;
					if (this.focusIndex < 0) this.focusIndex = 0
					//if (this.focusIndex === arkItems.length) this.focusIndex = arkItems.length-1;
				} else if (e.keyCode === 40) {
					this.focusIndex = this.focusIndex + 1;
					if (this.focusIndex === arkItems.length) this.focusIndex = arkItems.length-1;
				}
				//console.info(arkItems[this.focusIndex].innerText)
				arkItems[this.focusIndex].focus();
			}
		}
	}

	send() {
		if (this.input.value.trim().length > 0) {
			let sendData = {
				utterance: this.input.value,
				sessionKey: this.props.sessionKey,
				channelId: this.props.channelId,
				isLogin: this.props.isLogin,
				isDev: this.props.config.isDev,
				blockId: this.props.responseType === 'INPUT' ? this.props.blockId : '',
				blockName: this.props.responseType === 'INPUT' ? 'INPUT' : '',
				projectId: this.props.config.projectId,
			};

			this.props.send(sendData);
		}
	}

	reload() {
		//window.location.reload();
		this.props.reload();
	}

	sttSend(e) {
		if (e.value.trim().length > 0) {
			let sendData = {
				utterance: e.value,
				sessionKey: this.props.sessionKey,
				channelId: this.props.channelId,
				isLogin: this.props.isLogin,
				isDev: this.props.config.isDev,
				blockId: this.props.responseType === 'INPUT' ? this.props.blockId : '',
				blockName: this.props.responseType === 'INPUT' ? 'INPUT' : '',
				projectId: this.props.config.projectId,
			};

			this.props.send(sendData);
		}
	}

	openSTT() {
		try {
			window.requestRecord(); //App에서만 동작하는 코드
		} catch (error) {
			console.error(error);
		}
	}

	render() {
		return (
			<div className="chat_bottom">
				<div className="chat_bottom_inner">
					<ArkResult
						settingArk={this.props.settingArk}
						useArk={this.props.useArk}
						data={this.props.arkData}
						sessionKey={this.props.sessionKey}
						channelId={this.props.channelId}
						isLogin={this.props.isLogin}
						config={this.props.config}
						send={this.props.send}
						uiTexts={this.props.uiTexts}
						focusControl={this.handleKeyDown}
					/>
					<div className="inp_box">
						<div className="inp_txt">
							<label htmlFor="inquery" className="screen_out">
								문의내용
							</label>
							<input
								type="text"
								className="inp"
								id="inquery"
								placeholder={this.props.uiTexts.text_08}
								title="질문입력"
								autoComplete="off"
								value={this.props.value}
								onChange={this.handleChange}
								onKeyPress={this.handleKeyPress}
								onKeyDown={this.handleKeyDown}
								ref={(ref) => {
									this.input = ref;
								}}
							/>
							<button type="button" className="btn_send" id="img_btn" onClick={() => this.send()}>
								<span className="ico_comm ico_send" alt="문의 내용 전송 버튼">문의 내용 전송</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ChatBottom;
