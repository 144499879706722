import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as messageActions from '../modules/message';
import { APP_CONFIG } from '../utils/config';
import { openUrl, getLink } from '../utils/utils';

import '../css/select.scss';

class QuickRepliesSelect extends PureComponent {
	send(value) {
		let sendData = {
			utterance: value,
			sessionKey: this.props.message.sessionKey,
			channelId: this.props.message.channelId,
			isLogin: this.props.message.isLogin,
			isDev: this.props.config.isDev,
			projectId: this.props.config.projectId,
		};

		this.props.send(sendData);
	}

	openLink(url) {
		openUrl(url, APP_CONFIG.popWinTarget, this.props.fromApp);
	}

	click() {
		let value = this.select.options[this.select.selectedIndex].value;
		if (value !== '') {
			if (this.validURL(value) === true) {
				this.openLink(value);
			} else {
				this.send(value);
			}
		}
	}

	validURL(str) {
		var pattern = new RegExp('^(https?:\\/\\/)+');

		return pattern.test(str);
	}

	render() {
		console.log("this.props : ", this.props);
		const quickReplies = this.props.data.map((item, index) =>
			item.action === 'webLink' ? (
				<option key={index} value={getLink(item.webLinkUrl)} title={item.label + " 링크는 " + getLink(item.webLinkUrl) + " 입니다."}>
					{item.label}
				</option>
			) : (
				item.action === 'message' && (
					<option key={index} value={item.messageText} title={item.messageText}>
						{item.label}
					</option>
				)
			),
		);

		return (
			<div className="option_box">
				<div include="form-input-select()">
					<label for="안내를 원하는 내용을 선택해주세요"></label>
					<select
						required
						title={localStorage.getItem("select_title")}
						ref={(ref) => {
							this.select = ref;
						}}
					>
						<option value="" hidden>
							{this.props.uiTexts.text_34}
						</option>
						{quickReplies}
					</select>
				</div>
				<button
					className="btn_sel_ok"
					onClick={() => {
						this.click();
					}}
				>
					{this.props.uiTexts.text_35}
				</button>
			</div>
		);
	}
}
function mapStateToProps(state) {
	return { ...state };
}

function mapDispatchToProps(dispatch) {
	return { send: (payload) => dispatch(messageActions.sendMessage(payload)) };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickRepliesSelect);
