import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";

class ChatTop extends PureComponent {
	constructor(props) {
		super(props);

		this.handleClickSideMenu = this.handleClickSideMenu.bind(this);
		this.handleClickFeedback = this.handleClickFeedback.bind(this);

		this.state = {
			lang_is_active: false,
		};
	}

	handleClickSideMenu() {
		this.props.openSideMenu();
	}

	handleClickFeedback() {
		if (this.props.init === false) return;

		if (this.props.isLogin) {
			this.props.openFeedback();
		} else {
			this.props.controlOverlay({ toggle: true, closeAll: false });
			this.props.loginAlert();
		}
	}

	clickChatbotClose() {
		try {
			window.closeChatbot(); //App에서만 동작하는 코드
		} catch (error) {
			console.error(error);
		}
	}

	settingLang(lang) {
		this.props.setLang(lang);
		this.toggleSelect();
	}

	toggleSelect() {
		if (this.state.lang_is_active) {
			this.setState({
				lang_is_active: false,
			});
			this.langSelect.className = 'btn_language';
		} else {
			this.setState({
				lang_is_active: true,
			});
			this.langSelect.className = 'btn_language is_active';
		}
	}

	render() {
		let suggestionBtnClass = 'menu_btn btn_edit_r'
		//console.debug(this.props.theme);
		return (
			<div className="chat_header">
				<h1 className="h_tit">
					<span className={this.props.theme !== 'c1' ? 'ico_comm ico_bot_logo' : 'ico_comm ico_bot_logo1'}>대진대학교</span>
				</h1>
				<div className="btn_unit">

					<button type="button" className="menu_btn btn_menu" onClick={this.handleClickSideMenu}>
						<span className={this.props.theme !== 'c1' ? 'ico_comm ico_menu' : 'ico_comm ico_menu1'}>메뉴보기</span>
					</button>
				</div>
			</div>
		);
	}
}

export default ChatTop;
