import React, { PureComponent } from 'react';
import { APP_CONFIG } from '../../utils/config';

class Setting extends PureComponent {
	constructor(props) {
		super(props);
		this.handleClickClose = this.handleClickClose.bind(this);
		this.handelGreetingSetting = this.handelGreetingSetting.bind(this);
		this.handelQuickMenuSetting = this.handelQuickMenuSetting.bind(this);
		this.state = {
			greetingCount: 0,
			quickMenuCount : 0,
			maxCount: APP_CONFIG.maxSetting,
		};
	}

	componentDidMount() {
		let greetingCount = 0;
		this.props.data.greeting.map((item) => item.ISUSE === '1' && greetingCount++);

		let quickMenuCount = 0
		this.props.data.quickMenu.map(
            (item) => item.ISUSE === "1" && quickMenuCount++
        )

		this.setState({
			...this.state,
			greetingCount: greetingCount,
			quickMenuCount : quickMenuCount,
		});
	}

	handleClickClose() {
		this.props.setSetting();
		this.props.close();
	}

	handelGreetingSetting(e) {
		if (e.target.className === 'btn_control') {
			let currentCount = this.state.greetingCount + 1;
			if (this.state.maxCount < currentCount) {
				// 여기에 alert
				//console.error('[Alert MAX Count]')
				//this.props.alert(this.props.uiTexts.text_06);
				alert(this.props.uiTexts.text_06)
			} else {
				this.setState({
					...this.state,
					greetingCount: this.state.greetingCount + 1,
				});
				// this.state.greetingCount++
				this.props.toggleGreeting(e);
			}
		}

		if (e.target.className === 'btn_control is_active') {
			this.setState({
				...this.state,
				greetingCount: this.state.greetingCount - 1,
			});
			this.props.toggleGreeting(e);
		}
	}

	handelQuickMenuSetting(e) {
		if (e.target.className === 'btn_control') {
			let currentCount = this.state.quickMenuCount + 1;
			if (this.state.maxCount < currentCount) {
				// 여기에 alert
				//console.error('[Alert MAX Count]')
				//this.props.alert(this.props.uiTexts.text_37);
				alert(this.props.uiTexts.text_37)
			} else {
				this.setState({
					...this.state,
					quickMenuCount: this.state.quickMenuCount + 1,
				});
				// this.state.greetingCount++
				this.props.toggleQuickMenu(e);
			}
		}

		if (e.target.className === 'btn_control is_active') {
			this.setState({
				...this.state,
				quickMenuCount: this.state.quickMenuCount - 1,
			});
			this.props.toggleQuickMenu(e);
		}
	}

	tabClick(tabName) {
        // 설정 탭 클릭 이벤트 함수 
        let onTab = this.quickMenuTab
        let onContent = this.quickMenuSetting
        let offTab = this.greetingTab
        let offContent = this.greetingSetting

        if (tabName === "greeting") {
            onTab = this.greetingTab
            onContent = this.greetingSetting
            offTab = this.quickMenuTab
            offContent = this.quickMenuSetting
        }

        onTab.className = 'tab_menu_item active'
        onContent.className = 'tab_cont active'
        offTab.className = 'tab_menu_item'
        offContent.className = 'tab_cont'
    }
	

	render() {
		//console.log(this.props.data)
		
		return (
			<div className="layer_inner">
				<div className="layer_top">
					<h2 className="layer_h_tit">설정하기</h2>
				</div>
				<div className="layer_cont">
					<div className="tab_menu_box">
						<ul className="tab_menu_list">
							<li className="tab_menu_item active" data-tab="tab-1" ref={quickMenuTab => {this.quickMenuTab = quickMenuTab}}>
								<a href="#none" className="tab_menu_link" onClick={()=>this.tabClick('quickMenu')}><span>퀵메뉴</span></a>
							</li>
							<li className="tab_menu_item" data-tab="tab-2" ref={greetingTab => { this.greetingTab = greetingTab; }}>
								<a href="#none" className="tab_menu_link" onClick={()=>this.tabClick('greeting')}><span>그리팅메세지</span></a>
							</li>
						</ul>

						<div id="tab-1" className="tab_cont active" ref={quickMenuSetting => { this.quickMenuSetting = quickMenuSetting; }}>
							<div className="sch_area">
								<h2 className="screen_out">퀵메뉴</h2>
								<div className="message_set">
									{this.props.data !== null && this.props.data.quickMenu.length > 0 ? (
										<ul className="message_set_list">
											{this.props.data.quickMenu.map((item, index) => (
												<li className="message_set_item" key={index}>
													{item.LABEL}
													<button
														type="button"
														className={item.ISUSE === '1' ? 'btn_control is_active' : 'btn_control'}
														value={index}
														onClick={this.handelQuickMenuSetting}
													>
													</button>
												</li>
											))}
										</ul>
									) : (
										<span>{this.props.uiTexts.text_36}</span>
									)}
								</div>
							</div>
						</div>

						<div id="tab-2" className="tab_cont"  ref={greetingSetting => { this.greetingSetting = greetingSetting; }}>
							<div className="sch_area">
								<h2 className="screen_out">그리팅메시지</h2>
								<div className="message_set">
									{this.props.data !== null && this.props.data.greeting.length > 0 ? (
										<ul className="message_set_list">
											{this.props.data.greeting.map((item, index) => (
												<li className="message_set_item" key={index}>
													{item.LABEL}
													<button
														type="button"
														className={item.ISUSE === '1' ? 'btn_control is_active' : 'btn_control'}
														value={index}
														onClick={this.handelGreetingSetting}
													>
													</button>
												</li>
											))}
										</ul>
									) : (
										<span>{this.props.uiTexts.text_32}</span>
									)}
								</div>
							</div>
						</div>
					</div>


					<button type="button" className="btn_close layer">
						<span className="ico_comm ico_w_close" onClick={this.handleClickClose}>
							레이어 닫기
						</span>
					</button>
				</div>
			</div>
		);
	}
}

export default Setting;
