import React, { PureComponent } from 'react';
import { APP_CONFIG } from '../../utils/config'

class Feedback extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            defaultTxt : '이벤트를 위한 개인정보 수집에 동의하신다면, 아래 항목을 꼭 기재하여주세요.\n\n이름/학번: \n연락처:\n의견:',
            allowYn : 'N'
        }
        this.handleClickClose = this.handleClickClose.bind(this)
        this.handleClickTextarea = this.handleClickTextarea.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handelClickSend = this.handelClickSend.bind(this)
    }

    componentDidMount() {

    }

    handleClickClose() {
      this.props.close()
    }

    handleClickTextarea(e) {

        if (e.type === 'click') {
            e.target.placeholder = ''
        }
        if (e.type === 'blur') {
            if (e.target.value.trim() === '') {
                e.target.value = ''
                e.target.placeholder = this.state.defaultTxt
            }
        }
    }

    handleClickViewAgree() {
        if (this.inputElement.checked === false) {
            this.inputElement.checked = true;
            this.setState({allowYn : 'Y'})
            this.props.view()
        } else {
            this.inputElement.checked = false;
            this.setState({allowYn : 'N'})
        }
    }

    handleChangeInput(e) {
        if (e.target.checked === true) {
            this.setState({allowYn : 'Y'})
            this.props.view()
        } else {
            this.setState({allowYn : 'N'})
        }
    }

    handelClickSend () {
        if (this.textareaElement.value.trim() === '') {
            this.props.alert('의견을 입력해 주십시요')
        } else {
            const writeJSON = {
                "projectId" : APP_CONFIG.projectId,
                // "allowYn" : this.state.allowYn,
                "allowYn" : 'N',
                "feedbackContent" : this.textareaElement.value,
                "isDev" : this.props.isDev
            }

            this.props.write(writeJSON)
        }
    }

    render() {
        return (
            <div className="suggestion">
                <div className="tit">
                    <a href="#none" className="header">챗봇 의견 제안</a><a href="#none" className="top_menu fR" style={{paddingTop:'0px'}} onClick={this.handleClickClose}><img src="img/ico_close_w.png" width="20" height="20" style={{position:'absolute',right:'15px',top:'15px'}}alt="닫기" /></a>
                </div>
                <div className="inner">
                    <p className="txt">여러분의 의견으로 챗봇이 더 똑똑해질 수 있어요! <br />챗봇에 대한 의견을 제안해주세요.</p>
                    <dl>
                        <dt>소중한 의견 작성</dt>
                        <dd>
                            <textarea placeholder={this.state.defaultTxt} onClick={this.handleClickTextarea} onBlur={this.handleClickTextarea} ref={(textarea)=>{this.textareaElement = textarea}}/>
                            <p>※ 이벤트 참여를 원하신다면 이름/학번, 연락처, 챗봇에 대한 의견을 기재해주세요.</p>
                            {/* <p><input type="checkbox" onChange={this.handleChangeInput} ref={(checkbox)=>{this.inputElement = checkbox}}/> <a href="#none" onClick={() => this.handleClickViewAgree()}>(선택) 이벤트 참여를 위한 개인정보 수집 및 이용 동의</a></p> */}
                            <a href="#none" className="alert_btn" onClick={this.handelClickSend}>보내기</a>
                        </dd>
                    </dl>
                </div>
            </div>
        );
    }
};

export default Feedback;