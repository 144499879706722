import React, { Component } from 'react';
import { BasicCard, ListCard, SimpleText, SimpleGrid } from './Balloon/AnswerBalloon';
import CarouselBalloon from './Balloon/CarouselBalloon';
//import QuickReplies from './QuickReplies';
import QuickRepliesSelect from './QuickRepliesSelect';
import { getTalkTime } from '../utils/utils';
class BotMessages extends Component {
	constructor(props) {
		super(props);
		this.logoRef = React.createRef(); // 로고 요소 참조 생성
	}

	componentDidMount() {
		// 컴포넌트가 마운트된 후 로고 요소에 포커스 설정
		if (this.logoRef.current) {
			this.logoRef.current.focus();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextProps === this.props;
	}

	render() {
		let isCarousel = false;
		for (let i = 0; i < this.props.data.length; i++) {
			//console.debug(this.props.data[i]);

			if (this.props.data[i].carousel) {
				if (this.props.data[i].carousel.items.length > 1) {
					isCarousel = true;
					break;
				}
			}
		}

		let BotMessages = this.props.data.map((message, i) =>
			message.carousel ? (
				<CarouselBalloon key={i} data={message.carousel} isCarousel={isCarousel} />
			) : message.simpleText ? (
				<SimpleText key={i} data={message.simpleText} isGreeting={this.props.isGreeting} isCarousel={isCarousel} />
			) : message.basicCard ? (
				<BasicCard key={i} data={message.basicCard} isCarousel={isCarousel} />
			) : message.listCard ? (
				<ListCard key={i} data={message.listCard} />
			) : (
				message.simpleGrid && <SimpleGrid key={i} data={message.simpleGrid} />
			),
		);

		let inStyle;
		if (isCarousel) {
			inStyle = {
				width: '100%',
			};
		}
		let hiddenStyle = {
			textIndent: "-9999px"
		};
		return (
			<React.Fragment>
				<div className="bot_wrap" style={inStyle}>
					<span className="ico_comm ico_chatbot" style={hiddenStyle} ref={this.logoRef}
						  tabIndex="-1">대진대학교 챗봇 로고</span>
					<span className="bot_names" style={hiddenStyle}>지니US</span>
					<div className="bot_flex">
					{BotMessages}
					{this.props.quickBtns !== undefined && this.props.quickBtns !== null && (
						<QuickRepliesSelect data={this.props.quickBtns} uiTexts={this.props.uiTexts} />
					)}
					</div>
					<span className="time slide">{getTalkTime()}</span>
				</div>
			</React.Fragment>
		);
	}
}

export default BotMessages;
