import React, { PureComponent } from 'react';

class AgreeContent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
           "defaultTxt" : "1. 목적 : 참여자 경품(기프티콘) 추첨 및 발송\n2. 항목 : 포탈ID, 성명 및 연락처(ID연계)\n3. 보유기간 : 경품(기프티콘) 추첨 및 발송 후 6개월"
        }
        this.handleClickClose = this.handleClickClose.bind(this);
    }

    componentDidMount() {

    }

    handleClickClose() {
        this.props.close()
    }
    /*
        ----------------------------------------------------------------
        ** 개인정보 수집 및 이용 동의 ***
        1. 목적 : 참여자 경품(기프티콘) 추첨 및 발송
        2. 항목 : 포탈ID, 성명 및 연락처(ID연계)
        3. 보유기간 : 경품(기프티콘) 추첨 및 발송 후 6개월
        ※ 미동의 시 불이익 : 개인정보 수집 및 이용 동의를 하지 않을 경우 경품 추첨 및 선정 대상에 포함되지 않습니다.
        ※ 참여자 추첨 경품 : 아메리카노(스타벅스) 커피 기프티콘 30명
        ----------------------------------------------------------------
    */
    render() {
        return (
            <div>
                <div className="agreement">
                <div className="inner">
                    <dl>
                        <dt>개인정보 수집 및 이용 동의</dt>
                        <dd>
                            <textarea readOnly>{this.state.defaultTxt}</textarea>
                            <p>※ 미 동의 시 불이익 : 개인정보 수집 및 이용 동의를 하지 않을<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;경우 경품 추첨 및 선정 대상에 포함되지 않습니다.</p>
                            <p>※ 참여자 추첨 경품 : 아메리카노(스타벅스) 커피 기프티콘 30명</p>
                            <a href="#none" className="btn" onClick={this.handleClickClose}>확인</a>
                        </dd>
                    </dl>
                </div>
            </div>                
            </div>
        );
    }
};

export default AgreeContent;




