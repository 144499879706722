/* eslint-disable no-lone-blocks */
import React, { Component } from 'react';

class ArkResult extends Component {

	send(value) {
		let sendData = {
			utterance: value,
			sessionKey: this.props.sessionKey,
			channelId: this.props.channelId,
			isLogin: this.props.isLogin,
			isDev: this.props.config.isDev,
			projectId: this.props.config.projectId,
		};

		this.props.send(sendData);
	}

	convertHL(modified) {
		let ret = modified;
		if (ret.indexOf('<!HS>') > -1) {
			ret = modified.split('<!HS>').map((tempStr, tempKey) =>
				tempStr.indexOf('<!HE>') > -1 ? (
					tempStr.split('<!HE>').map((item, itemKey) =>
						itemKey === 0 ? (
							<mark className="search_keyword" key={itemKey}>
								{item}
							</mark>
						) : (
							<mark key={itemKey}>{item}</mark>
						),
					)
				) : (
					<mark key={tempKey}>{tempStr}</mark>
				),
			);
		}
		return ret;
	}

	settingArk(value) {
		this.props.settingArk(value);
	}

	focusControl(e) {
		this.props.focusControl(e)
	}

	render() {
		const displayBlock = {
			display: 'block',
		};

		return (
			<div className="ly_box_bottom auto" style={displayBlock}>
				<div className="ly_box_top">
					<strong className="ly_box_tit">{this.props.uiTexts.text_09}</strong>
					{this.props.useArk ? (
						<button
							type="button"
							className="btn_control is_active"
							onClick={() => {
								// eslint-disable-next-line no-lone-blocks
								{
									this.settingArk(false);
								}
							}}
							aria-label="자동완성 끄기"
						></button>
					) : (
						<button
							type="button"
							className="btn_control"
							onClick={() => {
								{
									this.settingArk(true);
								}
							}}
							aria-label="자동완성 켜기"
						></button>
					)}
				</div>
				<div className="auto_box" style={displayBlock}>
					<ul className="qna_list">
						{this.props.data !== undefined &&
							this.props.data !== null &&
							this.props.data.map((item, index) => (
								<li className="qna_item" style={displayBlock} key={index}>
									<button onClick={() => this.send(item.text)} className="qna_link" style={displayBlock} key={index} onKeyDown={(e) => this.focusControl(e)}>
										{item.text}
									</button>
								</li>
							))}
					</ul>
				</div>
			</div>
		);
	}
}

export default ArkResult;
