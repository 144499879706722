import React, { PureComponent } from 'react';
import { getTalkTime } from '../../utils/utils';

class LoadingBalloon extends PureComponent {
	render() {
		let inStyle = {
			width : "60px",
			paddingTop : "5px",
			minWidth : "60px"
		}
		let hiddenStyle = {
			textIndent: "-9999px"
		};
		return (
			<div className="bot_wrap">
				<span className="ico_comm ico_chatbot" style={hiddenStyle}>대진대학교 챗봇 로고</span>
				<span className="bot_names" style={hiddenStyle}>지니US</span>
				<div className="bot_box">
					<div className="bot_txt" style={inStyle}>
						<div className="three-balls">
							<div className="ball ball1"></div>
							<div className="ball ball2"></div>
							<div className="ball ball3"></div>
						</div>
					</div>
				</div>
				<span className="time slide">{getTalkTime()}</span>
			</div>
		);
	}
}

export default LoadingBalloon;
