import React, { PureComponent } from 'react';

class Alert extends PureComponent {
    constructor(props) {
        super(props);

        this.handleClickClose = this.handleClickClose.bind(this);
    }

    componentDidMount() {

    }

    handleClickClose() {
        this.props.close()
    }

    render() {
        return (
            <div className="alert_wrap">
            <div className="alert_inner">
                <div className="alert_cont">
                    <div className="alert_txt" dangerouslySetInnerHTML={{__html:this.props.message}}/>
                    <a className="alert_btn" href="#none" onClick={this.handleClickClose}>확인</a>
                </div>
             </div>
            </div>
        );
    }
};

export default Alert;