const LANG_KO = {
	text_00: '',
	text_01: '로그인 후 이용해 주십시요',
	text_02: '설정',
	text_03: '메뉴 위치',
	text_04: '챗봇 테마',
	text_05: '그리팅메시지로 받고 싶은 정보를 ON 해주세요!',
	text_06: '그리팅메시지는 최대 5개까지 설정 가능합니다.',
	text_07: '실시간 TOP 답변',
	text_08: '무엇을 도와드릴까요?',
	text_09: '자동완성 기능',
	text_10: '즐겨찾는 질문',
	text_11:
		'아직 즐겨찾는 질문이 없네요😥<br/>지니US에게 질문을 입력한 후 질문 말풍선의 <span className="ico_comm ico_bookmark">북마크</span>를<br/> 누르면 즐겨찾는 질문으로 등록이 가능해요😉',
	text_12: '즐겨찾는 질문 추가',
	text_13: '위 질문을 즐겨찾는 질문으로 추가하실래요?',
	text_14: '즐겨찾는 질문은 총 5개까지만<br/>등록 가능해요😥<br/>등록된 질문을 삭제한 후<br/>등록이 가능합니다!',
	text_15: '즐겨찾는 질문 삭제',
	text_16: '위 질문을 즐겨찾는 질문에서 삭제하실래요?',
	text_17: '지니US의견 제안',
	text_18: '지니US에 대한 의견을 <br/> 제안해주세요😀',
	text_19: '소중한 의견 작성',
	text_20: '불필요한 개인정보가 포함되지 않도록 작성 시 유의해주세요.',
	text_21: '작성한 질문에 적합한 답변을 입력해주세요. (글자수 1000자 제한)',
	text_22: '소중한 의견이 접수되었습니다.',
	text_23: '지니US',
	text_24: '로그인',
	text_25: '좌',
	text_26: '우',
	text_27: '로그아웃',
	text_28: '님',
	text_29: '확인',
	text_30: '예',
	text_31: '아니오',
	text_32: '그리팅 메시지 설정은 준비중입니다.',
	text_33: '보내기',
	text_34: '선택해주세요.',
	text_35: '선택',
	text_36: '퀵메뉴 설정은 준비중입니다.',
	text_37: '퀵메뉴는 최대 4개까지 설정 가능합니다.',
};

const LANG_EN = {
	text_00: 'Chat-E',
	text_01: 'Log in before using',
	text_02: 'Greeting text setting',
	text_03: 'Menu location',
	text_04: 'Chatbot theme',
	text_05: 'Turn on the information you want to receive as a greeting text!',
	text_06: 'You can set up to 5 greeting texts.',
	text_07: 'Real-time TOP answer ',
	text_08: 'How can I help you?',
	text_09: 'Auto-complete function',
	text_10: 'Favorite question',
	text_11:
		'There are no favorite questions 😥<br/>After asking a question to Chat-E, click ☆ on the question bubble to register it as a favorite question😉',
	text_12: 'Add your favorite questions',
	text_13: 'Do you want to add the above question as your favorite question?',
	text_14: 'You can register up to 5 favorite questions😥<br/>You can register after deleting the registered question!',
	text_15: 'Delete a favorite question',
	text_16: 'Do you want to remove the above question from your favorite questions?',
	text_17: 'Suggestions for chatbot',
	text_18: 'Please suggest comments on the chatbot 😀',
	text_19: 'Write your valuable opinion',
	text_20: 'Please be careful when writing not to include unnecessary personal information.',
	text_21: 'Please enter the appropriate answer to your question. (Up to 1000 characters)',
	text_22: 'Your valuable opinion has been accepted.',
	text_23: 'Chat-E',
	text_24: 'Log-in',
	text_25: 'left',
	text_26: 'right',
	text_27: 'Log-out',
	text_28: '',
	text_29: 'Confirm',
	text_30: 'Yes',
	text_31: 'No',
	text_32: 'The greeting message service is not yet available.',
	text_33: 'Send',
	text_34: 'Please select.',
	text_35: 'select',
};

const LANG_CN = {
	text_00: 'Chat-E',
	text_01: '请登录后使用',
	text_02: '设定问候信息',
	text_03: '菜单位置',
	text_04: '聊天机器人主题',
	text_05: '用问候信息，请打开想要得到的信息！',
	text_06: '最多可设定5个问候信息。',
	text_07: '实时TOP答复',
	text_08: '需要帮助吗？',
	text_09: '自动完成功能',
	text_10: '收藏夹',
	text_11: '还没有喜欢问的问题😥<br/>向Chat-E输入问题后，点击提问对话框的☆就可以登录为喜欢问的问题。😉',
	text_12: '添加常见问题',
	text_13: '上面问题要添加到常见问题中吗？',
	text_14: '常见问题最多可以注册5个😥<br/>删除已注册的问题后即可注册！',
	text_15: '常见问题删除',
	text_16: '想从常见问题中删除上面的问题吗？',
	text_17: '聊天机器人意见与建议',
	text_18: '请给我一些关于聊天机器人的建议😀',
	text_19: '填写宝贵的意见',
	text_20: '填写时请注意，不要包含不必要的个人信息。',
	text_21: '请为所填写的问题输入适合的答案。（字数限制1000字）',
	text_22: '收到您宝贵的意见。',
	text_23: 'Chat-E',
	text_24: '登录',
	text_25: '左',
	text_26: '右',
	text_27: '登出',
	text_28: '',
	text_29: '确认',
	text_30: '是的',
	text_31: '不',
	text_32: '问候语设置尚不可用。',
	text_33: '发送',
	text_34: '请选择.',
	text_35: '选择',
};

export { LANG_KO, LANG_EN, LANG_CN };
