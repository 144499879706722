import React, { PureComponent } from 'react';
import { APP_CONFIG } from '../../utils/config'

class LoginAlert extends PureComponent {

    handleClickClose() {
        if (this.props.clickSideMenu === false) {
            this.props.clickOverlay()
        }
        this.props.close()
    }

    render() {
        return (
            <div className="alert_wrap">
                <div className="alert_inner">
                    <div className="alert_cont">
                        <div className="alert_txt">로그인 후 이용해 주십시요.</div>
                        <a className="alert_btn" href={APP_CONFIG.logIn} target="_self" style={{ marginRight: '10px' }}>로그인하기</a>
                        <a className="alert_btn" href="#none" onClick={() => this.handleClickClose()}>닫기</a>
                    </div>
                </div>
            </div>
        );
    }
};

export default LoginAlert;




