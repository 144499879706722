/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { PureComponent } from 'react';
import { APP_CONFIG } from '../../utils/config';

class SideMenu extends PureComponent {
	constructor(props) {
		super(props);

		this.handleClickClose = this.handleClickClose.bind(this);
		this.handleClickTip = this.handleClickTip.bind(this);
		this.handleAlert = this.handleAlert.bind(this);
		this.handleClickSetting = this.handleClickSetting.bind(this);
		this.handleQuickMenuClick = this.handleQuickMenuClick.bind(this);
		//this.handleClickLoginAlert = this.handleClickLoginAlert.bind(this)

		// 메뉴 레이어 버튼에 대한 ref 생성
		this.closeButtonRef = React.createRef();
		this.ButtonRef1 = React.createRef();
		this.ButtonRef2 = React.createRef();
	}

	componentDidMount() {
		// 컴포넌트가 마운트될 때 버튼에 포커스를 줌
		if (this.closeButtonRef.current) {
			this.closeButtonRef.current.focus();
		}

		let quickMenuLis = document.getElementsByClassName('sub_menu_item')
		if (quickMenuLis.length > APP_CONFIG.maxSetting) {
			for (let i = 0; i < quickMenuLis.length; i++) {
				if (i > APP_CONFIG.maxSetting-1) quickMenuLis[i].style.display = "none";
			}
		}
	}

	state = {
		isOpenTheme: false,
	};

	handleKeyDown = (event) => {
		// Enter 키가 눌렸을 때, 클릭과 동일한 효과를 주는 로직
		if (event.key === 'Enter') {
			// 현재 포커스가 있는 엘리먼트가 버튼이라면 클릭 이벤트를 호출
			if (document.activeElement === this.ButtonRef1.current) {
				this.handleClickTip();
			}
			if(document.activeElement === this.ButtonRef2.current){
				this.handleClickSetting();
			}
		}
	};

	handleQuickMenuClick(e) {
		// this.totalClose()
		this.props.close();
		let sendData = {
			utterance: e.target.value,
			sessionKey: this.props.sessionKey,
			channelId: this.props.channelId,
			isLogin: this.props.isLogin,
			isDev: this.props.config.isDev,
			projectId: this.props.config.projectId,
		};

		this.props.send(sendData);
	}

	handleClickClose() {
		// this.totalClose()
		if (this.props.isLogin) {
			this.props.setSetting();
		}
		this.props.close();
	}


    handleClickTip() {
		this.props.openUseTip();
		var focusableElements = Array.from(document.querySelectorAll('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'));
		focusableElements[0].focus();
	}


	handleClickSetting() {
		if (this.props.isLogin) {
			this.props.openSetting();
			//var focusableElements = Array.from(document.querySelectorAll('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'));
			//focusableElements[0].focus();
		} else {
			this.props.loginAlert();
			//var focusableElements = Array.from(document.querySelectorAll('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'));
			//focusableElements[0].focus();
		}
		
	}

	handleAlert() {
		alert('준비중입니다.');
	}

	clickPosition(value) {
		this.props.setMenuPostion(value);
	}

	openThemeSet() {
		const currentState = this.themeSetDiv.style.display;
		//console.debug(currentState);
		if (currentState === '' || currentState === 'none') {
			this.themeSetDiv.style.display = 'block';
			this.setState({ isOpenTheme: true });
		} else {
			this.themeSetDiv.style.display = 'none';
			this.setState({ isOpenTheme: false });
		}
	}



	render() {
		return (
				<div className="sub_menu_inner" ref={(ref) => { this.sidemenuElement = ref;}}>
					<button type="button" className="btn_close" ref={this.closeButtonRef} onClick={this.handleClickClose}>
						<span className="ico_comm ico_close" alt="메뉴 레이어 닫기 버튼">메뉴 레이어 닫기</span>
					</button>
					<div className="profile_box">
						{this.props.isLogin ? ( //로그인인 경우
							<React.Fragment>
								<span className="chat_logo_box">
									<img src="./img/profile_before.png" alt="프로필 이미지" />
								</span>
								<span className="profile_name">{this.props.userName}</span>
								<a href={APP_CONFIG.logOut} className="logout_link">
									<span className="ico_comm">{this.props.uiTexts.text_28}</span>
								</a>
							</React.Fragment>
						) : (
							//로그인이 아닌 경우
							
							<React.Fragment>
								<span className="chat_logo_box">
									<img src="./img/profile_before.png" alt="프로필 이미지" />
								</span>
								<span className="profile_name">{this.props.uiTexts.text_23}</span>
								{<a href={APP_CONFIG.logIn} className="btn_login">
									{this.props.uiTexts.text_24}
								</a>}
							</React.Fragment>
						)}
					</div>
					{this.props.quickMenu && ( //퀵메뉴 표시
						<ul className="sub_menu_list">
							{this.props.quickMenu.map(
								(button, index) =>
									button.ISUSE === '1' && (
										<li className="sub_menu_item" key={index}>
											<button
												type="button"
												value={button.UTTERANCE}
												className="sub_menu_link"
												onClick={this.handleQuickMenuClick}
											>
												{button.LABEL}
											</button>
										</li>
									),
							)}
						</ul>
					)}
					<div className="sub_btn_group">
						<ul className="setting_list">
							{
							<li className="list_item">
								<span className="ico_info" alt="이용안내 가이드"></span>
								<div className="sub_btn" tabIndex="0" ref={this.ButtonRef1} onKeyDown={this.handleKeyDown} onClick={this.handleClickTip}>
									<span>이용안내 가이드</span>
								</div>
							</li>
							}
							<li className="list_item" >
								<span className="ico_setting" alt="설정하기"></span>
								<div className="sub_btn" tabIndex="0" ref={this.ButtonRef2} onKeyDown={this.handleKeyDown} onClick={this.handleClickSetting}>
									<span>설정하기</span>
								</div>
							</li>
						</ul>
					</div>


				</div>
			//</div>
		);
	}
}

export default SideMenu;
