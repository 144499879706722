import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as messageActions from '../../../modules/message';
import { nl2br, nl2brElement } from '../../../utils/utils';
import { APP_CONFIG } from '../../../utils/config';

import Flicking from '@egjs/react-flicking';
/*
import '@egjs/react-flicking/dist/flicking.css';
// Or, if you have to support IE9
import '@egjs/react-flicking/dist/flicking-inline.css';
*/
//import sc from 'styled-components';
class SimpleGrid extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			currentPage: 0,
		};
	}

	clickMessage(value, label) {
		let sendData = {
			utterance: value,
			sessionKey: this.props.message.sessionKey,
			channelId: this.props.message.channelId,
			isLogin: this.props.message.isLogin,
			isDev: this.props.config.isDev,
			blockId: '',
			projectId: this.props.config.projectId,
		};
		const select_title_message = "" + label.replace("\n"," ") + " 관련 선택"
		localStorage.setItem("select_title", select_title_message);
		this.props.send(sendData);
	}

	clickPhoneNum(phoneNum) {
		document.location.href = 'tel:' + phoneNum;
	}

	clickProject(projectId) {
		const param = {
			projectId: projectId,
		};
		this.props.get(param);
	}

	clickPageDot(pIndex) {
		this.carousel.moveTo(pIndex, 500);
		this.changePageDot(pIndex);
		//this.moveEnd(this.changePageDot());
	}

	changePageDot(pIndex) {
		//console.debug('before', this.carousel.index);
		this.setState({
			currentPage: pIndex,
		});
		//console.debug('after', this.carousel.index);
	}

	render() {
		const getButton = (data, idx) => {
			const tabIndex = 0;
			if (data.action === 'webLink') {
				if (data.target) {
					return (
						<a href={data.webLinkUrl} target={data.target} className="icon_box" tabIndex={tabIndex} key={idx}>
							<span className="icon" style={{ backgroundImage: 'url(' + data.image.url + ')' }}></span>
							<span className="m_txt">{nl2brElement(data.label)}</span>
						</a>
					);
				} else {
					return (
						<a href={data.webLinkUrl} target={APP_CONFIG.popWinTarget} className="icon_box" tabIndex={tabIndex} key={idx}>
							<span className="icon" style={{ backgroundImage: 'url(' + data.image.url + ')' }} alt=""></span>
							<span className="m_txt">{nl2brElement(data.label)}</span>
						</a>
					);
				}
			}
			if (data.action === 'message') {
				return (
					<a href="#none" className="icon_box" tabIndex={tabIndex} onClick={() => this.clickMessage(data.messageText, data.label)} onFocus={(event) => ((0 <= idx) && (idx < 6)) ? this.clickPageDot(0) : (((6 <= idx) && (idx < 12)) ? this.clickPageDot(1) : this.clickPageDot(2))
					} key={idx}>
						<span className="icon" style={{ backgroundImage: 'url(' + data.image.url + ')' }} alt={data.image.alt}></span>
						<span className="m_txt">{nl2brElement(data.label)}</span>
					</a>
				);
			}
			if (data.action === 'phone') {
				return (
					<a href="#none" className="icon_box" tabIndex={tabIndex} onClick={() => this.clickPhoneNum(data.phoneNumber)} key={idx}>
						<span className="icon" style={{ backgroundImage: 'url(' + data.image.url + ')' }}></span>
						<span className="m_txt">{nl2brElement(data.label)}</span>
					</a>
				);
			}
			if (data.action === 'project') {
				return (
					<a href="#none" className="icon_box" tabIndex={tabIndex} onClick={() => this.clickProject(data.id)} key={idx}>
						<span className="icon" style={{ backgroundImage: 'url(' + data.image.url + ')' }}></span>
						<span className="m_txt">{nl2brElement(data.label)}</span>
					</a>
				);
			}
		};

		const getItems = (startIdx, endIdx) => {
			let ret = this.props.data.buttons.map((button, idx) => idx >= startIdx && idx < endIdx && <li key={idx}>{getButton(button, idx)}</li>);
			return ret;
		};

		const setRows = this.props.data.buttonRow !== undefined ? this.props.data.buttonRow : 2;
		const setColumns = 3;
		const pagePerButtons = setColumns * setRows;
		const buttonCnt = this.props.data.buttons.length;
		const totalPages = Math.ceil(buttonCnt / setColumns / setRows);

		const getGrids = () => {
			let startIdx = 0;
			let endIdx = 0;
			let ret = [];
			for (let i = 0; i < totalPages; i++) {
				startIdx = i * pagePerButtons;
				endIdx = pagePerButtons * (i + 1);
				ret[i] = (
					<div className="chat_menu" style={{ width: '100%', display: 'inline-block' }} key={i}>
						{getItems(startIdx, endIdx)}
					</div>
				);
			}
			return ret;
		};

		let pagination = () => {
			let ret = [];

			for (let i = 0; i < totalPages; i++) {
				if (this.state.currentPage === i) {
					ret[i] = <li className="checked" tabIndex="0" onClick={() => this.clickPageDot(i)} onKeyDown={(e) => e.key === 'Enter' && this.clickPageDot(i)} key={i}></li>;
				} else {
					ret[i] = <li tabIndex="0" onClick={() => this.clickPageDot(i)} onKeyDown={(e) => e.key === 'Enter' && this.clickPageDot(i)} key={i}></li>;
				}
			}
			return ret;
		};


			return (
				<div className="bot_box">
					<div className="bot_txt main">
						<div class="img_box" style={{"textAlign" : "center"}}>
							<img src={this.props.data.image.url} alt={this.props.data.image.alt ? this.props.data.image.alt : "이미지"}/>
						</div>
						<div className="menu_box" dangerouslySetInnerHTML={{ __html: nl2br(this.props.data.text) }} />
						<br />

						<Flicking
							ref={(carousel) => {
								this.carousel = carousel;
							}}
							deceleration={0.0075}
							horizontal={true}
							circular={false}
							threshold={20}
							duration={500}
							defaultIndex={0}
							inputType={['touch', 'mouse']}
							moveType="snap"
							autoResize={true}
							adaptive={false}
							bound={false}
							hanger={0}
							anchor={0}
							onChanged={() => this.changePageDot(this.carousel.index)}
						>
							{getGrids()}
						</Flicking>
						<div className="pagination">
							<ul>{pagination()}</ul>
						</div>
					</div>
				</div>
       );
    }
};

function mapStateToProps(state) {
	return { ...state };
}

function mapDispatchToProps(dispatch) {
	return {
		send: (payload) => dispatch(messageActions.sendMessage(payload)),
		get: (payload) => dispatch(messageActions.getGreeting(payload)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleGrid);
