import React, { PureComponent } from 'react';
import guideImg from '../../images/chat_guide.png'
import { APP_CONFIG } from '../../utils/config';

class UseTip extends PureComponent {

    clickClose() {
        this.props.closeUseTip()
    }

    setExpries() {
        this.props.setExpires()
    }

    render() {
        const inlineStyle = {
            display : "block"
        }
        return (
            <React.Fragment>
            <div className="dimmed guide" style={inlineStyle}></div>
            <div className="layer_wrap guide" style={inlineStyle} >
                <div className="layer_inner">
                    <div className="layer_cont">
                        <div className="layer_btn_group">
                            { this.props.showExpiresBtn && (
                                <button
                                    type="button"
                                    id="guideNeverCloseButton"
                                    tabIndex="0"
                                    className="btn_never_view"
                                    onClick={() => this.setExpires()}
                                >30일간 다시 보지 않기</button>
                            )}
                            <button
                                type="button"
                                tabIndex="0"
                                className="btn_ly_close"
                                onClick={() => this.clickClose()}
                            >닫기</button>
                        </div>
                        <img src={guideImg} className="img_guide" alt="지니US 이용 가이드 대진대학교에 대해 궁금한 것을 지니US에게 질문해 보세요! 학번으로 로그인 이벤트 참여 버튼 선택, 질문 자동완성"/>
                    </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
};

export default UseTip;